
import { Component, Vue } from 'vue-property-decorator';

import { RegistrationField, RegistrationFields } from './model';
import GeneralModule from '@/store/modules/general/GeneralModule';
import ApiClient from '@/api/api.client';
import InviteRequest from '@/models/dto/invite.request';

import Input from '@/components/ui/input/Input.vue';
import Button from '@/components/ui/button/Button.vue';
import LanguageSelector from '@/components/ui/language-selector/LanguageSelector.vue';
import Checkbox from '@/components/ui/checkbox/Checkbox.vue';

import Logo from '@/assets/images/logo.svg';
import Notify from '@/services/helpers/notify';
import i18n from '@/services/i18n';

@Component({
  name: 'RegistrationInvited',
  components: {
    Logo,
    Input,
    Button,
    Checkbox,
    LanguageSelector,
  },
})
export default class Marks extends Vue {
  public fields: RegistrationFields = {
    name: {
      value: '',
      isValid: null,
      validator: this.isNotEmpty,
    },
    surname: {
      value: '',
      isValid: null,
      validator: this.isNotEmpty,
    },
    password: {
      value: '',
      isValid: null,
      validator: this.isNotEmpty,
    },
    consent: {
      value: true,
      isValid: true,
      validator: (value: boolean) => value,
    },
  };
  public inviteId = '';
  public password = '';
  public consent = true;
  public isError = false;

  get isAllFieldsValid(): boolean {
    const keys: string[] = Object.keys(this.fields);
    let isAllValid = true;

    for (const key of keys) {
      const field = this.fields[key];
      isAllValid = isAllValid && field.validator(field.value);
    }

    return isAllValid;
  }

  async mounted() {
    this.inviteId = window.location.hash.slice(1,window.location.hash.length);
  }
  validatePassword(value: string) 
  {
    if(value.length <= 7)
    {
      this.fields.password.isValid = false
      Notify.error(i18n.t('Пароль должен содержать 8 символов').toString());
    }
    else
    {
      this.fields.password.isValid = true
    }
  }
  validate(field: RegistrationField): boolean {
    field.isValid = field.validator(field.value);
    return field.isValid;
  }
  clearFalseValidation(field: RegistrationField) {
    field.isValid = field.isValid === false ? null : field.isValid;
  }

  isNotEmpty(value: string): boolean {
    return 0 < value.length;
  }
  isItEmail(value: string) {
    return /.+@.+\..+/.test(value);
  }
  onNameChange(value: string): void {
    this.fields.name.value = value;
  }

  onSurnameChange(value: string): void {
    this.fields.surname.value = value;
  }

  onPasswordChange(value: string): void {
    this.fields.password.value = value;
  }

  onConsentChange(value: boolean) {
    this.fields.consent.value = value;
    this.fields.consent.isValid = value;
  }

  async onSubmit(e: Event) {
    e.preventDefault();
    const request = new InviteRequest();
    request.password = String(this.fields.password.value);
    request.first_name = String(this.fields.name.value);
    request.last_name = String(this.fields.surname.value);
    request.hash = this.inviteId;
    if(String(this.fields.password.value).length <= 7)
    {
      this.fields.password.isValid = false
      Notify.error(i18n.t('Пароль должен содержать 8 символов').toString());
    }
    else
    {
      this.fields.password.isValid = true
      const result = await ApiClient.invite.accept(request.toJson());
      GeneralModule.setAuthToken(result.token);
      window.location.reload();
    }
  }
}
